<template>
    <div>
        <el-steps
            :active="active"
            finish-status="success"
            style="padding: 0 10%"
        >
            <el-step title="上传文件"></el-step>
            <el-step title="执行导入"></el-step>
            <el-step title="导入完成"></el-step>
        </el-steps>
        <div
            v-show="active === 0"
            style="padding: 0 10%"
        >
            <first-step
                ref="importRef"
                :show-download="showDownload"
                :class-type-options="classTypeOptions"
                :type-options="typeOptions"
                :import-form="importForm"
                :import-info="importInfo"
            >
                <slot></slot>
            </first-step>
        </div>
        <div v-show="active === 1">
            <second-step
                :show="show"
                :importLoading="importLoading"
                :import-form="importForm"
                :upload-excel-header="uploadExcelHeader"
                :system-excel-header="systemExcelHeader"
                :key-map="keyMap"
                :import-info="importInfo"
            />
        </div>
        <div v-show="active === 2">
            <third-step :success-data="successData" />
        </div>
    </div>
</template>

<script>
import firstStep from "./firstStep";
import secondStep from "./secondStep";
import thirdStep from "./thirdStep";

export default {
    props: {
        active: {
            type: Number,
        },
        classTypeOptions: {
            type: Array,
        },
        importForm: {
            type: Object,
        },
        showDownload: {
            type: Boolean,
        },
        systemExcelHeader: {
            type: Array,
        },
        typeOptions: {
            type: Array,
        },
        uploadExcelHeader: {
            type: Array,
        },
        keyMap: {
            type: Object,
        },
        importInfo: {
            type: Object,
        },
        successData: {
            type: Number,
        },
        show: {
            type: Boolean,
            default: true,
        },
        importLoading: {
            type: Boolean,
        },
    },
    components: {
        firstStep,
        secondStep,
        thirdStep,
    },
};
</script>

<style>
.is-success .el-step__icon-inner {
    color: #ffffff;
}
.is-success .is-text {
    background-color: #3C7FFF;
    border: 2px solid #3C7FFF;
}
.is-success.el-step__title {
    color: #3C7FFF;
}
.el-step__head.is-success {
    color: #3C7FFF !important;
    border-color: #3C7FFF !important;
}
.is-success .el-step__line {
    background: #3C7FFF;
    border-radius: 1px 0px 0px 1px;
}
.is-process .el-step__line {
    background: linear-gradient(
        90deg,
        #66b3ff 0%,
        rgba(102, 179, 255, 0.1) 100%
    );
    border-radius: 1px 0px 0px 1px;
}
.is-process .el-step__icon-inner {
    color: #3C7FFF;
}
.is-process .is-text {
    border: 2px solid #3C7FFF;
}
.is-process.el-step__title {
    color: #3C7FFF;
}
.el-step .is-horizontal .el-step__line {
    top: 13px !important;
}
.el-step__icon {
    width: 28px !important;
    height: 28px !important;
}
.el-step__title {
    font-size: 14px;
    margin-left: -12px;
}
</style>
